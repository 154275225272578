
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Attachment = {
  blob: Blob;
  path: string;
};

type Expense = {
  id: number;
  date: Date;
  expenses: number;
  kilometers: number;
  notes: string;
  upload_ids: number[];
};

@Component({
  components: {
    ExpenseForm: () =>
      import("@/app/modules/expenses/components/ExpenseForm.vue"),
    Attachment: () => import("@/app/modules/expenses/components/Attachment.vue")
  }
})
export default class UpdateExpensePage extends Vue {
  @Prop(Number) readonly expenseId!: number;

  removeDialog: boolean = false as boolean;
  expense: null | Expense = null;
  errors: object = {} as object;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get isApiFetching() {
    return this.$store.getters["api/isApiFetching"];
  }

  mounted() {
    this.fetchExpenseById();
  }

  fetchExpenseById() {
    this.$store
      .dispatch("expenses/fetchById", this.expenseId)
      .then(({ data }) => (this.expense = data));
  }

  async update(attachments: Attachment[]) {
    let upload_ids: number[] = [];

    if (attachments.length != 0) upload_ids = await this.upload(attachments);
    if (this.expense?.upload_ids) upload_ids.push(...this.expense.upload_ids);

    const data = {
      id: this.expenseId,
      body: {
        ...this.expense,
        upload_ids
      }
    };

    this.$store
      .dispatch("expenses/update", data)
      .then(() => this.$router.back())
      .catch(error => (this.errors = error.response.data.errors));
  }

  async upload(attachments: Attachment[]): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      attachments.map((_, i) =>
        formData.append(`file[${i}]`, _.blob, moment().toLocaleString())
      );

      this.$store
        .dispatch("expenses/uploadFiles", formData)
        .then(({ data }) => resolve(data.map(_ => _.id)))
        .catch(error => reject(error));
    });
  }

  remove() {
    this.$store
      .dispatch("expenses/remove", this.expenseId)
      .then(() => this.$router.back())
      .catch(error => (this.errors = error.response.data.errors));
  }

  removeFile(id: number) {
    this.$store
      .dispatch("expenses/removeFile", id)
      .then(() => this.fetchExpenseById())
      .catch(error => (this.errors = error.response.data.errors));
  }
}
